import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

import Home from "../pages/Home";
import Interview from "../pages/Interview";
import Detail from "../pages/Detail";
import Question from "../components/detailsPage/question";
import Message from "../pages/Message";

export const AppRoutes = (): JSX.Element => (
  <ChakraProvider>
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/about/:id" element={<Interview />}></Route>
        <Route path="/detail/:kind" element={<Detail />} />
        <Route path="/question" element={<Question />} />
        <Route path="/message" element={<Message />} />
      </Routes>
    </BrowserRouter>
  </ChakraProvider>
);

import { Center, Stack, Box } from "@chakra-ui/react";
import EmployeesList from "../components/topPage/EnployeesList";
import { CompanyDetailsDatas } from "../data/CompanyDetails";
import { CompanyTopsDatas } from "../data/CompanyTops";

import TitleCard from "../components/topPage/parts/TitleCard";
import FooterMenu from "../components/topPage/footer/FooterMenu";
import Header from "../components/topPage/header/Header";
import CopyRight from "../components/common/copyRight";
import ScrollToTop from "../components/common/ScrollToTop";

const Home = (): React.ReactElement => {
  return (
    <Box mb={"96px"}>
      <Header
        name="株式会社 三秀"
        message={`
        1年目から企画提案<br>
        <h1>新規事業の中心メンバーに！</h1><br>
        `}
        subMessage="やりたいこと、どんどん言える<br>三秀で自分の可能性を試そう！"
      ></Header>
      <Center>
        <Stack h="100%">
          <Box backgroundColor={"#BEFFFB"} px={8} py={12}>
            <Stack h="100%" spacing={8}>
              {CompanyTopsDatas.map((data, index) => (
                <TitleCard
                  title={data.title}
                  subTitle={data.subTitle}
                  context={data.context}
                  image={data.image}
                  imageExplanation={data.imageExplanation}
                  transition={data.transition}
                  transitionContent={data.transitionContent}
                  video={data.video}
                />
              ))}
              <EmployeesList />
            </Stack>
          </Box>
          <Box px={8}>
            <FooterMenu />
          </Box>
          <ScrollToTop></ScrollToTop>
          <CopyRight content={CompanyDetailsDatas.copyRight} />
        </Stack>
      </Center>
    </Box>
  );
};

export default Home;

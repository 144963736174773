export const QuestionDatas = {
  name: "三秀 人事担当",
  question: [
    {
      content: "転勤はありますか？",
    },
    {
      content: "自家用車での通勤は可能ですか？",
    },
    {
      content: "自家用車での通勤は可能ですか？",
    },
  ],
  answer: [
    {
      content: `
      転勤はありません
      鈴鹿市にある本社工場勤務となります。
      `,
    },
    {
      content: `
      はい。可能です。
      敷地内に駐車場があります。
      `,
    },
    {
      content: `
      ソフトボールチームがあります！休日には他チームとの試合もあって盛り上がっていますよ。
      `,
    },
  ],
};

import SpeechBubble from "../common/speechBubble";
import { QuestionDatas } from "../../data/Questions";

import { Image, HStack, Stack, Text, Box } from "@chakra-ui/react";

const Question = () => {
  const name = QuestionDatas.name;
  const questions = QuestionDatas.question;
  const answers = QuestionDatas.answer;

  type ContentType = {
    content: string;
  }[];

  const combinedArray: ContentType = questions.reduce<ContentType>(
    (acc, curr, idx) => {
      return [...acc, curr, answers[idx]];
    },
    []
  );

  return (
    <>
      <Box p={4} ml={-8}>
        <HStack>
          <Image src="/image/question_human_guts.png" alt="image not found" style={{ width: '80px', height: '72px' }}></Image>
          <Stack spacing={4}>
            <Text textAlign={"left"}>{name}</Text>
            <SpeechBubble content={"ご質問は遠慮なくどうぞ。"} />
          </Stack>
        </HStack>
      </Box>

      {combinedArray.map((data, index) => {
        const isQuestion: boolean = index % 2 === 0 ? true : false;

        if (isQuestion) {
          return (
            <Box key={index} display="flex" justifyContent="flex-end" p={4}>
              <SpeechBubble content={data.content} direction="right" />
            </Box>
          );
        } else {
          return (
            <Box key={index} alignSelf={"flex-start"} p={4} ml={-8}>
              <HStack>
                <Image
                  src="/image/question_human_guts.png"
                  alt="image not found"
                  style={{ width: '80px', height: '72px' }}
                  alignSelf={"flex-start"}
                ></Image>
                <Stack spacing={4}>
                  <Text textAlign={"left"}>{name}</Text>
                  <SpeechBubble content={data.content} />
                </Stack>
              </HStack>
            </Box>
          );
        }
      })}

      <Box p={4} ml={-8} mb={"64px"}>
        <HStack>
          <Image src="/image/question_human_guts.png" alt="image not found" style={{ width: '80px', height: '72px' }}></Image>
          <Stack spacing={4}>
            <Text textAlign={"left"}>{name}</Text>
            <SpeechBubble
              content={
                "これからも、ご質問の多かった事項については、こちらで発信してまいりますので、いつでもお気軽にお問合せください！"
              }
            />
          </Stack>
        </HStack>
      </Box>
    </>
  );
};

export default Question;

export const EmployeesData = [
  {
    video: "https://www.youtube.com/embed/hZt3I85b5ZQ?si=aBru0EfPNGKgEr5V",
    image:
      "https://docs.google.com/drawings/d/e/2PACX-1vRgB8d-Vypgpj954i4Lja5bDPieaTEhUyEkvdrrUKK-5p31657BXG5mWyZmGeqS9W9ZjXK5Hx6CDuum/pub?w=360&h=510",
    department: "型枠事業部",
    name: "鈴木 和弘",
    ruby: "すずき かずひろ",
    career: "企画課 班長",
    interviewData: {
      interviewImage:
        "https://docs.google.com/drawings/d/e/2PACX-1vS-8MhX6cER_tSbTJ8YwCoTMsm1MXRbfPcwVknaLG--OGrknnzzrj7ggmg5I2iVEhEWWsEEyVnKhRK5/pub?w=922&h=612",
      subInterviewImage: "",
      department: "型枠事業部　企画課",
      content: "コンクリート二次製品の型枠設計で知った仕事の奥深さ",
      schedule: `
      none
      `,
      careerPath: `
      none
      `,
      holiday: `
      none
      `,
      interviews: [
        {
          title: "現在の仕事内容",
          content:
            "私の主な仕事は、コンクリート二次製品の型枠の設計を手掛けています。U字溝やL型擁壁ボックスなど、道路を走っているとよく見かけるものを設計しています。",
        },
        {
          title: "入社当時のエピソード",
          content: `
          実は、入社当時は型枠事業部はまだありませんでした。事業部が立ち上げられた当初、石川県の金沢市へ勉強のために行きましたが、当時は全くの未知の領域で、正直、辞めることも考えました。しかし10か月間の勉強を経て、型枠について深く理解することができ、これならいけると確信し、今に至っています。完成した型枠を見るたびに、さらに改善したいとの思いが湧き上がりますね。今はリーダーとして頑張っています。
          `,
        },
        {
          title: "三秀の良いところ",
          content:
            "上司も同僚も非常にフレンドリー。そのおかげで、非常に仕事しやすい環境に恵まれています。",
        },
        {
          title: "今後の目標",
          content:
            "実は、新しい第２工場が立ち上がる予定です。そのため、仕事の量はさらに増えることでしょう。私の目標は、これまで以上にスピーディに、そして使いやすく、作りやすい型枠を設計していくことです。",
        },
        {
          title: "後輩に対しての思い",
          content:
            "私は、これまでの失敗から得た学びを若い人たちに伝えたいと強く感じています。そして、この型枠事業部がこれからも続いていくために、私がこれまで得てきた知識や経験をしっかりと伝えていきたいです。",
        },
        {
          title: "就職活動中の皆さんへ",
          content:
            "もしモノづくりに興味があるなら、この職場は最適です。自分で設計した型枠を使って製品を作る過程を、最初から最後まで体験することができます。その製品が実際の現場で使われるのを見るのは、本当にやりがいがあります。ぜひ、この楽しさを共有しましょう。",
        },
      ],
    },
  },
  {
    video: "https://www.youtube.com/embed/9vyirIHOPzY?si=_HFv3wphwR590EG1",
    image:
      "https://docs.google.com/drawings/d/e/2PACX-1vTS4cvEynHFl7pell_iz3o1eQJ8vGYqRa6d2yhIuaUlt0H-J8iMGP-wqJ0vbFvq9hc68vWzbt-_wzo6/pub?w=277&h=385",
    department: "部品事業部",
    name: "宇田 広",
    ruby: "うだ ひろ",
    career: "製造課 製造係",
    interviewData: {
      interviewImage:
        "https://docs.google.com/drawings/d/e/2PACX-1vS4Rz3nFz4saqFFqSbEg25qjcs-038KvC_zTZSRhiqziKjepH9n0eg4xW5zu66F8Um6VFOWk7m2ApYF/pub?w=924&h=614",
      subInterviewImage: "",
      department: "部品事業部　製造課",
      content: "製品ひとつひとつに集中することを日々心がけけています",
      schedule: `
      none
      `,
      careerPath: `
      none
      `,
      holiday: `
      none
      `,
      interviews: [
        {
          title: "入社へのきっかけ、決め手は？",
          content:
            "実は社長が若いことが魅力で、何か新しい風が吹き込むのではないかと期待して入社しました。",
        },
        {
          title: "現在の業務内容と課題",
          content:
            "私は作業現場で、主にロボット操作や溶接の仕事を担当しています。",
        },
        {
          title: "仕事をする上で意識していること",
          content: "量産なので、良品を作ることが私の最大の意識ポイントです。",
        },
        {
          title: "三秀の魅力・良いところ",
          content:
            "多くのメーカーとの取引があるので、毎日違う仕事に挑戦できることが最大の魅力です。",
        },
        {
          title: "これからの目標",
          content:
            "自分の行動と成果を通じて、この会社での昇進を目指しています。",
        },
        {
          title: "休日の過ごし方",
          content:
            "ボディビルをやっているので、主に筋トレに励んでいます。休日だけではなく、週に6日、1時間半から2時間ほどですね。",
        },
        {
          title: "新事業の提案について",
          content:
            "ボディビルは一般的にスポーツとは言い難いですが、実業団の一環として導入することを提案したことがあります。",
        },
        {
          title: "就職活動中の皆さんへ",
          content:
            "現在、昇進のチャンスが広がっています。早く昇進を目指す方には特におすすめの環境です！",
        },
      ],
    },
  },
  {
    video: "https://www.youtube.com/embed/jc4iOJzXwWM?si=keczcKZTW3UcWBTF",
    image:
      "https://docs.google.com/drawings/d/e/2PACX-1vS43DhGgFxrh8c3GxWJk80VLQfPxPyAZkmSQNpsS7I8DmleWUpo-pN9ukhATggRkWl_4hLDt00joEv1/pub?w=300&h=393",
    department: "部品事業部",
    name: "奥地 尚人",
    ruby: "おくち なおと",
    career: "製造課 製造係",
    interviewData: {
      interviewImage:
        "https://docs.google.com/drawings/d/e/2PACX-1vTlNbfOewyQyb6MVXLY0QWUk06EDOFLxM1DaZ4BHJjeYFiyOaqaTxDnLqNvSdav6HlsTwmSSM0Aj92N/pub?w=925&h=618",
      subInterviewImage: "",
      department: "部品事業部　製造課",
      content: "やりがいがある仕事がたくさんアットホームな職場です",
      schedule: `
      none
      `,
      careerPath: `
      none
      `,
      holiday: `
      none
      `,
      interviews: [
        {
          title: "入社へのきっかけ、決め手は？",
          content:
            "転職活動中にホームページを閲覧し、創業の長さや多岐にわたる事業展開に魅力を感じました。",
        },
        {
          title: "現在の業務内容と課題",
          content:
            "金属のプレスやレーザー板金部門の計画を立て、それに基づく業務を担当しています。常に「より良いものを効率よく作る」ことを意識しています。<br>工場の中なので、夏場の暑さ、重たい物を持ち運ぶ作業は大変ですね。繁忙期には、残業が増えることもあります。",
        },
        {
          title: "仕事をする上で意識していること",
          content:
            "毎朝、部下に必ず声をかけるよう心掛けています。今日（のコンディションやスケジュール）は大丈夫か？ 今日はこういう製品あるけれども問題ないかとか、常に声をかけています。また、健康管理においても、特に夏の時期は水分補給を促す声掛けも大切にしています。",
        },
        {
          title: "三秀の魅力・良いところ",
          content:
            "頼りになる先輩方が多く、わからないことがあっても安心して質問できる環境が整っています。",
        },
        {
          title: "休日の過ごし方",
          content:
            "家でゆっくりしたり、子供たちと遊びに行ったりしています。平日の仕事終わりに、たまに同僚と飲みに行くこともあります。",
        },
        {
          title: "就職活動中の皆さんへ",
          content:
            "当社にはやりがいのある仕事が多いです。大変なこともあるかもしれませんが、風通しが良くアットホームな職場です。ぜひ一度、経験してみてください。",
        },
      ],
    },
  },
  {
    video: "https://www.youtube.com/embed/YpaOEmkMzaA?si=L5tX2EH6mkAXL-nM",
    image:
      "https://docs.google.com/drawings/d/e/2PACX-1vRI46FRzTgfvU8qP_YWUer1pzThIEkFjguQ4aKgQP6hCchkSVXmzqJfvBszuGa_qX6psHWscr5WfJ60/pub?w=348&h=439",
    department: "部品事業部",
    name: "小式海 拓人",
    ruby: "こしきみ たくと",
    career: "管理課 管理係 係長",
    interviewData: {
      interviewImage:
        "https://docs.google.com/drawings/d/e/2PACX-1vRQlcQwqzGHw9TeD5D7N2nGjPfWmekcRnRj8Q8xyr4iUxzvRQ00GqSNcnkikht_UdlTfKSlLSEFOzn0/pub?w=814&h=512",
      subInterviewImage: "",
      department: "部品事業部　管理課",
      content: "新事業の人材支援を拡大し、人口減の社会をフォローしたい",
      schedule: `
      none
      `,
      careerPath: `
      none
      `,
      holiday: `
      none
      `,
      interviews: [
        {
          title: "入社経歴と再入社に至った経緯",
          content:
            "私の勤務歴はトータルで14年。実は、一度退職しましたが、1年後に再入社しました。最初の入社のきっかけは、リーマンショックの影響で求人がほとんど無く、私の得意としていた品質管理の仕事を探していた時に、三秀を見つけたんです。自宅からやや遠いため少し迷いましたが、結果的に入社を選びました。<br>再入社の動機は、当時課長だった現社長とのつながりでした。彼の想いに共感し、再びこの場に戻ることを決意しました。",
        },
        {
          title: "現在の業務内容と課題",
          content:
            "主に購買管理、納入管理、そして総務や人事も手掛けています。最近では購買管理におけるコストダウンが難しくなってきました。全般的にコストが上がっている中で、どうやって経費を削減するか、それが一番の課題。人事面では、若手人材の不足。街を歩いても若者の姿が少ないのですが、いかに彼らを引き寄せるのが現在の大きな課題です。",
        },
        {
          title: "仕事への姿勢",
          content:
            "仕事をする上でのモットーは、「今日と明日で同じ仕事をするな」ということ。常に進化し、前日より質の高い仕事を目指しています。量や効率も大切ですが、それ以上に「質」にこだわっています。そして、「明日やろうは馬鹿野郎」。思いついたこと、必要なことはすぐに行動に移すことを心掛けています。",
        },
        {
          title: "三秀の良いところ",
          content:
            "当社の一番の特色は、若い社長のもと、従業員との距離が近いこと。そして、労働組合との良好な関係を保ちつつ、より良い方向へと進めている姿勢です。さらに多国籍な雇用が行われており、どの国籍であれ、質の高い人材を重視して採用しているのが私たちの強みです。",
        },
        {
          title: "休日の過ごし方",
          content:
            "休日は家族と過ごすことが多いですね。そして、趣味は投資。具体的には株式投資に興じています。その知識や視点は、実は仕事にも活かされているんです。",
        },
        {
          title: "今後の目標",
          content:
            "今後の目標としては、登録支援機関の事業を拡大していくこと。人口減の中で、どうやって社会をサポートしていくか、その答えの一つとしてその事業を位置づけています。",
        },
        {
          title: "就職活動中の皆さんへ",
          content:
            "三秀は良い意味で、自由で自分がしたいことが実現できると思うので、若い方もチャンスが多々あると思いますので、ぜひ一緒に働きましょう。",
        },
      ],
    },
  },
  {
    video: "https://www.youtube.com/embed/sTS6Q0f7XyY?si=FA2ZAm8p-nH0e2L3",
    image:
      "https://docs.google.com/drawings/d/e/2PACX-1vQu7qAo0-4UILK4icaCARwLz5-LprkFXW4r5s3_ucy__8OfIH5O2ZxO8qLBEXSmrBIP3-CoOco5kIG0/pub?w=313&h=457",
    department: "部品事業部",
    name: "村田　裕斗",
    ruby: "むらた ゆうと",
    career: "製造課 技術部",
    interviewData: {
      interviewImage:
        "https://docs.google.com/drawings/d/e/2PACX-1vQ7aAWhrn-NYlrK81N9j0YkZ7c8G-mpjdmc121QGgr42aV-_vFCkat4cti0jn2UsptOThVBKM-48Anu/pub?w=924&h=619",
      subInterviewImage: "",
      department: "製造課 技術部",
      content: "これがやりたいという方、ぜひ一緒に仕事をしましょう",
      schedule: `
      none
      `,
      careerPath: `
      none
      `,
      holiday: `
      none
      `,
      interviews: [
        {
          title: "入社へのきっかけ、決め手は？",
          content:
            "三秀の社員募集は、ウェブサイトで目にしました。その中で、板金プレスの製品だけでなく、キャンプグッズの制作も行っていることがあり、私の趣味がキャンプなので、自分の作りたいものを実現できるのではないかと思い、応募しました。",
        },
        {
          title: "現在の業務内容と課題",
          content:
            "主に金属部品の量産を担当しています。ただ、量産に移行するまでの初期段階で、例えば「このような箱を100個作りたい」という要望があった場合、それをいかに安く、かつ安定して製造できるかの方法を模索する部分も手掛けています。<br>一番の課題は、経験豊富な先輩方や同僚とのコミュニケーションです。我々の部署は人との関わりが深いので、どう接して、良好な関係を築いていくかを大切にしています。",
        },
        {
          title: "日々、意識していること",
          content:
            "常に笑顔を絶やさず、人とのコミュニケーションを大切にしています。",
        },
        {
          title: "三秀の良いところ",
          content:
            "キャンプ事業など、多岐にわたる分野での取り組みがある点が魅力です。積極的に提案や挑戦をする姿勢が評価され、それに基づいて様々な業務を任せてもらえる環境があります。",
        },
        {
          title: "休日の過ごし方",
          content:
            "はじめにも申し上げましたが、キャンプが趣味で、特にバイクでのキャンプが好きです。また、釣りも楽しみなので、アウトドアを中心に過ごしています。休みの日は、短いときは1泊、長いときは2、3泊のキャンプを計画しています。おすすめは静岡の「ふもとっぱら」。アニメ「ゆるキャン△」でも取り上げられた場所で、富士山が目の前に広がる絶景スポットです。また、三重県の熊野や和歌山県境にもおすすめの場所がありますので、ぜひ私に尋ねてください！",
        },
        {
          title: "新しい事業について",
          content:
            "2023年8月に、MAKUAKEというサイトで新製品のクラウドファンディングをスタートしました。私が設計・開発を担当した焚き火台で、長持ちするような設計を心掛けています。また、オプションで好きな文字の刻印も可能です。",
        },
        {
          title: "今後の目標",
          content:
            "弊社は90年の長い歴史を有しており、その中で令和の時代に合わない点も確かに存在します。そのような部分を見直し、より効率的な組織を目指していきたいと思っています。",
        },
        {
          title: "就職活動中の皆さんへ",
          content:
            "自分の実現したいアイディアや製品がある方、一緒に仕事をして、それを形にしていきたいと思っています。ぜひ、ご応募ください。",
        },
      ],
    },
  },
];

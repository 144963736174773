export const CareerOppotunitiesData = [
  {
    image: "/image/adoption1.png",
    name: "募集職種",
  },
  {
    image: "/image/adoption2.png",
    name: "募集要項",
  },
  {
    image: "/image/adoption3.png",
    name: "採用フロー",
  },
  {
    image: "image/adoption4.png",
    name: "待遇・福利厚生",
  },
  {
    image: "image/adoption5.png",
    name: "インターンシップ",
  },
  {
    image: "image/adoption7.svg",
    name: "よくある質問",
  },
  {
    image: "image/adoption8.png",
    name: "お問合せ",
  },
];

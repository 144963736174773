import { Box, Center, Flex, VStack, Text, Image } from "@chakra-ui/react";
import Back from "../components/common/Back";
import RenderTextWithBreaks from "../utils/indention";

const Message = () => {
  return (
    <>
      <Center>
        <VStack>
          <Box
            bg="#212AFE"
            w="90vw"
            maxW={"512px"}
            p={4}
            pl={8}
            color={"white"}
            borderRadius={5}
          >
            <Text fontSize={"xl"} fontWeight={700}>
              社長メッセージ
            </Text>
          </Box>
          <Box w="80vw" maxW={"480px"}>
            <Text fontSize={"xl"} fontWeight={700} color={"#212AFE"}>
              弊社のモットーは「即行動」！前向きな人を求めています
            </Text>
          </Box>

          <Box w={"90vw"} maxW={"480px"}>
            <Image
              src={
                "https://docs.google.com/drawings/d/e/2PACX-1vQR27YpNXCIrRDb50RVfddOqL03FPy9QKkU3bVnHYVAktITUB0JDyyTa5p-IsxwhyrjfTEC5ECzpQL1/pub?w=683&h=683"
              }
              alt="image not found"
              mb={-3}
            />
            <Box mt={5}>
              <Box mb={5}>
                <Text fontWeight={700}>{"塩原 輝久"}</Text>
                <Text>{"代表取締役社長"}</Text>
              </Box>
              <RenderTextWithBreaks
                inputStr={
                  "当社には、大きな柱として建設機械やフォークリフトを量産する事業部と、型枠事業部があります。従業員約70名という規模にしては顧客社数が多いのですが、小回りが利く分、市場変化の対応に強い会社になってきていると思っています。<br>採用に際しては、ひとつでも得意なことがある人に惹かれます。何かを頑張っている強い想いは、会社の成長に繋がりますから。弊社の新しい柱をたてるために、社員のみなさんから色々な意見を提案してもらっています。新しいことは不安を伴いますが、一歩を踏み出すことが楽しめるような方が欲しいですね。<br>中途採用の場合、入社試験は面接のみです。人柄や考え方、積極性などを見ています。いままでどんな失敗したか？　どう乗り越えたか？ といったことを聞いています。「あ、この人いいな！」と思ったら、その場で給料決めて、即採用します（笑）<br>弊社はこれから色々なことにチャレンジしていきます。例えば最近では、人材育成や管理・派遣などもスタートしました。また、金属加工でもキャンプ用品の自社製品をはじめ、色々なことに挑戦しています。皆さんの意見をたくさん出していただければ、もしかしたらその一つが会社の事業の柱になっていくかもしれません。積極的に意見を出せる人を募集していますので、よろしくお願いいたします。<br>"
                }
                fontSize="mb"
              />
            </Box>
          </Box>
        </VStack>
      </Center>

      <Box mt={"64px"} mb={"96px"}>
        <Center w="100%">
          <Flex maxW="512px" justifyContent="flex-end" w="100%">
            <Box>
              <Back></Back>
            </Box>
          </Flex>
        </Center>
      </Box>
    </>
  );
};

export default Message;

import MenuBar from "./MenuBar";
import { CareerOppotunitiesData } from "../../../data/CareerOppotunities";
import { Text, Box, Divider } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const FooterMenu = () => {
  const navigate = useNavigate();

  const Details: string[] = [
    "jobType",
    "requirements",
    "flow",
    "welfare",
    "internship",
    "question",
  ];

  const toDetail = (id: number) => {
    if (Details.length <= id) {
      window.location.href = "https://sansyu-jp.com/contact";
    } else {
      navigate("/detail/" + Details[id]);
    }
  };

  return (
    <Box mb={"32px"}>
      <Text
        textAlign={"center"}
        fontWeight={700}
        fontSize={"2xl"}
        mt={"32px"}
        mb={"32px"}
      >
        採用情報
      </Text>
      {CareerOppotunitiesData.map((data, index) => (
        <Box mb={"8px"} onClick={() => toDetail(index)}>
          <MenuBar key={index} image={data.image} text={data.name} />
          <Divider borderColor={"#1DC6FC"}></Divider>
        </Box>
      ))}
    </Box>
  );
};

export default FooterMenu;

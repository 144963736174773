export const CompanyTopsDatas = [
  {
    title: "メッセージ",
    subTitle: "弊社のモットーは「即行動」！<br>前向きな人を求めています",
    image:
      "https://docs.google.com/drawings/d/e/2PACX-1vQR27YpNXCIrRDb50RVfddOqL03FPy9QKkU3bVnHYVAktITUB0JDyyTa5p-IsxwhyrjfTEC5ECzpQL1/pub?w=683&h=683",
    imageExplanation: "代表取締役社長 塩原 輝久",
    context: `
    当社には、大きな柱として建設機械やフォークリフトを量産する事業部と、型枠事業部があります。
    従業員約70名という規模にしては顧客社数が多いのですが、小回りが利く分、市場変化の対応に強い会社になってきていると思っています。<br>
    　<br>
    採用に際しては、ひとつでも得意なことがある人に惹かれます。
    何かを頑張っている強い想いは、会社の成長に繋がりますから。弊社の新しい柱をたてるために、社員のみなさんから色々な意見を提案してもらっています。
    新しいことは不安を伴いますが、一歩を踏み出すことが楽しめるような方が欲しいですね。
    弊社はこれから色々なことにチャレンジしていきます。例えば最近では、人材育成や管理・派遣などもスタートし、キャンプ用品の自社製品をはじめ、色々なことに挑戦しています。積極的に意見を出せる人を募集していますので、よろしくお願いいたします。
    `,
    video: "https://www.youtube.com/embed/XI9MvnfwbbA?si=2PUiDgiQRYvDrHC3",
    transition: "none",
    transitionContent: "none",
  },
  {
    title: "Column",
    subTitle: "ホームページを飾るイラスト",
    image:
      "https://docs.google.com/drawings/d/e/2PACX-1vR6jlesWrOqV9AUOB32k6ArsNAz6Wl3NUnKhh9nJ7oK2a13mZqf8TWgUVlhRWV6RumfIPpVV5QFJHDG/pub?w=928&h=480",
    imageExplanation: "",
    context: `
    制作当時(2021年）、三秀の目標は自社製品を開発して一般顧客に販売することでした。イラストにはこれまで手掛けた製品に加え、未来となる2031年、2050年の年号と、これまで作ったことのない製品（テントやフライパン）なども描きました。
      今、三秀はこれらの夢を少しずつ実現しています。<br>
      　<br>
      イラスト：AMISIKI（ami okamoto）さん<br>
      オランダ在住のアーティストです<br>
    `,
    transition: "http://amisiki.com/",
    transitionContent: "AMISIKIさん",
  },
];

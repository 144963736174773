import { Box, VStack, Center, Text } from "@chakra-ui/react";
import RenderTextWithBreaks from "../../utils/indention";

type Props = {
  title?: string;
  content?: string;
};

const InterviewContent: React.FC<Props> = ({ title, content }) => {
  return (
    <Box mt={"16px"}>
      <Center>
        <VStack>
          <Box
            bg={"#1DC6FC"}
            p={2}
            pl={30}
            w={"90vw"}
            maxW={"512px"}
            borderRadius={5}
          >
            <Text color={"white"} fontWeight={700}>
              {title ?? "none"}
            </Text>
          </Box>
          <Box w={"80vw"} maxW={"480px"} pr={4}>
            <RenderTextWithBreaks
              inputStr={content ?? "none"}
              textColor={"#626262"}
              fontWeight={700}
              fontSize="md"
            />
          </Box>
        </VStack>
      </Center>
    </Box>
  );
};

export default InterviewContent;

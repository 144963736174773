import React from "react";
import { Text } from "@chakra-ui/react";

interface Props {
  inputStr: string;
  center?: boolean;
  fontWeight?: number;
  textColor?: string;
  fontSize?: string;
  textSpace?: number;
}

const RenderTextWithBreaks: React.FC<Props> = ({
  inputStr,
  center = false,
  fontWeight = 500,
  textColor,
  fontSize = "xl",
  textSpace = 0,
}) => {
  const splittedTexts = inputStr.split("<br>");

  return (
    <>
      {splittedTexts.map((text, index) => {
        // h1で囲われているかどうかで太文字にするか決める
        const isBold = /<h1>.*<\/h1>/.test(text);
        const dynamicFontWeight = isBold ? "bold" : fontWeight;
        const cleanedText = text.replace(/<\/?h1>/g, "");

        return (
          <Text
            key={index}
            m={textSpace}
            fontWeight={dynamicFontWeight}
            fontSize={fontSize}
            textAlign={center ? "center" : undefined}
            textColor={textColor}
          >
            {cleanedText}
          </Text>
        );
      })}
    </>
  );
};

export default RenderTextWithBreaks;

import {
  Card,
  CardBody,
  Image,
  Stack,
  Heading,
  Box,
  Center,
  Link,
  Text,
  AspectRatio,
} from "@chakra-ui/react";

import { ArrowForwardIcon } from "@chakra-ui/icons";
import RenderTextWithBreaks from "../../../utils/indention";
import { useEffect, useState } from "react";

type Props = {
  image?: string;
  title?: string;
  subTitle?: string;
  context?: string;
  transition?: string;
  transitionContent?: string;
  imageExplanation?: string;
  video?: string;
};

const TitleCard: React.FC<Props> = ({
  image,
  title,
  subTitle,
  context,
  transition,
  transitionContent,
  imageExplanation,
  video,
}) => {
  const [hasLink, setHasLink] = useState<boolean>(true);

  useEffect(() => {
    if (transition === "none" || transitionContent === "none") {
      setHasLink(false);
    }
  }, [transition, transitionContent]);

  return (
    <Card maxW="sm">
      <CardBody>
        <Stack mt="6" spacing="3">
          <Center h="100%">
            <Heading size="md">{title}</Heading>
          </Center>
          <RenderTextWithBreaks
            textColor="#1D41FC"
            fontWeight={700}
            inputStr={subTitle ?? "none"}
            fontSize="xl"
            textSpace={-2}
            center={true}
          />
          <Image src={image} alt="image not found" borderRadius="lg" />
          <Text mb={8} textAlign={"center"}>
            {imageExplanation ?? "none"}
          </Text>
          <RenderTextWithBreaks
            inputStr={context ?? "none"}
            fontSize="md"
            textSpace={-2}
          />
          {video ? (
            <>
              <Center>
                <Box w="80vw" maxW={"512px"}>
                  <AspectRatio maxW={"512px"}>
                    <iframe
                      src={video ?? "none"}
                      allowFullScreen
                      title={video}
                      style={{ display: "block", margin: "auto" }}
                    ></iframe>
                  </AspectRatio>
                </Box>
              </Center>
            </>
          ) : (
            <></>
          )}
        </Stack>
      </CardBody>
      <Box w="100%" textAlign="right" mb={4} pr={4}>
        {hasLink ? (
          <>
            <ArrowForwardIcon />
            <Link href={transition}>{transitionContent}</Link>
          </>
        ) : (
          <div></div>
        )}
      </Box>
    </Card>
  );
};

export default TitleCard;

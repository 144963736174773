export const CompanyDetailsDatas = {
  copyRight: "Copyright (C) 2023 SANSYU Co., Ltd.  All Rights Reserved.",
  jobType: [
    {
      content: `
      <h1>型枠事業部（設計）</h1><br>
      　<br>
      CADを使って道路側溝などコンクリート二次製品をつくるための「型枠」を設計します。
      顧客の要望に合わせたカスタマイズ設計が8割、2割が新製品です。道路のインフラ整備や街づくり、
      災害復旧などに欠かせない製品。自分が手がけた型枠で製造されたコンクリート製品は街中で見ることができますよ！<br>
      `,
    },
    {
      content: `
      <h1>部品事業部（製造技術）</h1><br>

      <h1>　</h1><br>
      建設機械や輸送用部品をつくるため生産工程の順序を考えたり、CADで治具を設計したり、試作品を製作します。
      部品を量産できるような環境を整えることが、あなたのミッション！<br>
      ２～3名のチームで分担して進めていきますが、ゆくゆくは顧客との打ち合わせもお任せします。
      自分のアイデアを活かして考えた方法で部品が製造され、完成したものを見たときには、達成感があります。<br>
      `,
    },
    {
      content: `
      <h1>工程管理・溶接・板金加工</h1><br>

      　<br>
      ・工程管理・現場改善の実務又は管理<br>
      ・溶接作業<br>
       （スポット溶接、ＭＩＧ溶接、仕上げ etc.）<br>
      ・板金加工 等の業務を行っていただきます。 <br>
      ※ 工程管理を主として業務していただきます。<br>
      ※ 教育・訓練体系に基づく階層別、職務別、目的別の研修を計画的に実施していきます。<br>
      ※ 将来の幹部候補生の募集になります。<br>
      `,
    },
  ],
  requirements: [
    {
      content: `

      □　業界・職種未経験の方も歓迎 <br>
      □　要普通自動車免許（AT可） <br>
      □　高卒以上<br>
      □　45歳迄の方　（※）<br>
      ※若年層の長期キャリア形成を図るため
      `,
    },
    {
      content: `
      <h1>こんな方を募集しています</h1><br>
      　<br>

      □　モノづくりが好き <br>
      □　アイデアをカタチにしたい<br>
      □　誠実に仕事と向き合える<br>
      □　いろいろなことに興味を持てる<br> 
      □　新しいことに挑戦したい
      `,
    },
    {
      content: `
      <h1>こんな方を募集しています</h1><br>
      　<br>

      ・設計や製造技術のほかに、製造、営業など、いろいろな職種が活躍する当社だからこそ、
      将来の可能性も無限大∞自分にピッタリの仕事に出会えます。 <br>
      　<br>
      受注生産でさまざまなモノづくりにチャレンジできる環境。
      とことんモノづくりを楽しみたい方なら、やりがいを感じられること間違いなしです！
      `,
    },
  ],
  flow: [
    {
      content: `
      <h1>採用フロー</h1><br>
      　　<br>
      書類選考 ▶ 面接 ▶ 内定・採用 <br>
      　　<br>
      ※中途採用の場合は面接のみ
      `,
    },
    {
      content: `
      <h1>提出書類</h1><br>
      　<br>
      □　履歴書（写真つき）<br>
      □　成績証明書　　（新卒の場合）<br>
      □　卒業見込証明書（新卒の場合）<br>
      `,
    },
  ],
  welfare: [
    {
      content: `
      <h1>給与</h1><br>
        　<br>
        月給：20万円～35万円 <br>
        経験・スキルを考慮します！<br>
        　<br>
        【昇給】 年1回 平均昇給率2% <br>
        【賞与】 年2回 ※4.2ヵ月分（今年度） <br>
        【年収例】 <br>
        420万円／20代（月給24万円＋諸手当＋賞与）<br>
        550万円／30代（月給34万円＋諸手当＋賞与）<br>
        　<br>
        通勤手当（月1万5000円まで） <br>
        時間外手当(1.30倍の割増率で支給）<br>
        皆勤手当（月8000円） <br>
        住宅手当(月1万1000円） <br>
        家族手当(配偶者：月1万5000円、子一人当たり：月7000円※二人まで) <br>
        退職金制度(勤続2年以上) <br>
        昼食代の半額補助(仕出し弁当)<br>
      `,
    },
    {
      content: `
        <h1>勤務時間</h1><br>
        　<br>
        勤務時間：8:00～17:00 <br>
        (休憩時間 1時間00分) <br>
        　<br>
        ※残業は月平均20h程度です。
      `,
    },
    {
      content: `
        <h1>休日・休暇</h1><br>
        　<br>
        完全週休2日制（土日）<br>
        GW <br>
        夏季休暇<br> 
        年末年始休暇<br> 
        産休・育休(取得実績あり)<br> 
        有給休暇(時間単位の取得もOKです）<br>
          　<br>
        【年間休日】 117日
      `,
    },
    {
      content: `
        <h1>待遇・福利厚生</h1><br>
        　<br>
        【試用期間】 <br>
          最長6ヵ月 <br>
          労働条件は本採用と同じです。<br>
          　<br>
          【保険制度】<br>
          ・健康保険 <br>
          ・厚生年金 <br>
          ・雇用保険 <br>
          ・労災保険 <br>

          各種社会保険完備<br>
          退職金制度(勤続年数2年以上)<br>
          無料駐車場有(マイカー通勤可)<br>
          昼食代半額補助(仕出し弁当)<br>
          　<br>
          ★労働組合がしっかり機能しており、社員が安心して働けるように労働環境改善に努めています


      `,
    },
  ],
  internship: [
    {
      content: `
      <h1>インターンシップについて</h1><br>
      　<br>
      スケジュールの合うタイミングで実施いたします<br>
      　<br>
      対象 　　不問<br>
      　<br>
      期間　　1週間程度<br>
      　<br>
      体験内容　会社説明／製造業務体験
      `,
    },
  ],
  session: [
    {
      content: `
        <h1>WEB開催</h1><br>
        説明会情報ず<br>

      `,
    },
  ],
};

import HamburgerMenu from "./HamburgerMenu";
import RenderTextWithBreaks from "../../../utils/indention";
import {
  Stack,
  Center,
  Text,
  Image,
  Box,
  HStack,
  Spacer,
  Button,
} from "@chakra-ui/react";

type Props = {
  name: string;
  message?: string;
  subMessage?: string;
};

const Header: React.FC<Props> = ({ name, message, subMessage }) => {
  return (
    <Box>
      {/* ヘッダー固定用 */}
      <Center w={"100vw"} h={{ base: "30vw", md: "8vw" }}>
        <Box
          position={"fixed"}
          top={0}
          zIndex={5}
          background={"rgba(255,255,255,0.8)"}
          pt={8}
        >
          <HStack>
            <Box ml={4}>
              <HamburgerMenu />
            </Box>
            <Spacer></Spacer>
            <Button
              bg={"#FF0000"}
              color="#FFFFFF"
              mr={4}
              as="a"
              href={"https://sansyu-jp.com/"}
            >
              三秀HP
            </Button>
          </HStack>

          <Center w={"100vw"} maxW={"1024px"}>
            <Stack
              h="100%"
              w={{
                base: "100%",
                md: "40%",
              }}
              mb={4}
            >
              <Text textAlign={"center"} fontWeight={600}>
                採用特設サイト
              </Text>
              <Text textAlign={"center"} fontSize={"2xl"} fontWeight={800}>
                {name}
              </Text>
            </Stack>
          </Center>
        </Box>
      </Center>

      <Center>
        <Stack
          h="100%"
          w={{
            base: "100%",
            md: "40%",
          }}
          maxW={"512px"}
          mb={4}
          mt={10}
        >
          <Image src="/image/OL_SansyuTOP.jpg" w="100%" mb={1}></Image>
          <RenderTextWithBreaks
            inputStr={message ?? "none"}
            center={true}
            fontSize={"3xl"}
            fontWeight={600}
            textColor="#0832C9"
            textSpace={-2}
          />
          <Box mt={2}>
            <RenderTextWithBreaks
              inputStr={subMessage ?? "none"}
              center={true}
              fontWeight={600}
              fontSize={"xl"}
              textSpace={-2}
            />
          </Box>
        </Stack>
      </Center>
    </Box>
  );
};

export default Header;
